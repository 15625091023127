// src/components/DateRangePickerFilter.js
import React from 'react';
import { Box, TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';

function DateRangePickerFilter(props) {
  const { item, applyValue } = props;

  const handleStartDateChange = (newValue) => {
    applyValue({ ...item.value, startDate: newValue });
  };

  const handleEndDateChange = (newValue) => {
    applyValue({ ...item.value, endDate: newValue });
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box display="flex" flexDirection="column" gap={2}>
        <DatePicker
          label="Start Date"
          value={item.value?.startDate ? dayjs(item.value.startDate) : null}
          onChange={handleStartDateChange}
          slotProps={{ textField: { fullWidth: true } }}
        />
        <DatePicker
          label="End Date"
          value={item.value?.endDate ? dayjs(item.value.endDate) : null}
          onChange={handleEndDateChange}
          slotProps={{ textField: { fullWidth: true } }}
        />
      </Box>
    </LocalizationProvider>
  );
}

export default DateRangePickerFilter;