import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';

import SignIn from 'components/SignIn';
import Home from 'components/Home';
import FinalizeSignIn from 'components/FinalizeSignIn';
import PrivateRoute from 'components/PrivateRoute';

function App() {
  return (
    
        <Router>
          <Routes>
            <Route path="/signin" element={<SignIn />} />
            <Route path="/finalize-signin" element={<FinalizeSignIn />} />
            <Route 
              path="/home" 
              element={
                <PrivateRoute>
                  <Home />
                </PrivateRoute>
              } 
            />
            <Route path="/" element={<Navigate to="/signin" replace />} />
          </Routes>
        </Router>
  );
}

export default App;