// src/components/FinalizeSignIn.js
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { isSignInWithEmailLink, signInWithEmailLink } from 'firebase/auth';
import { auth } from '../firebase/config';
import { setUser } from '../store/authSlice';
import { 
  Box, 
  Typography, 
  Container, 
  CircularProgress 
} from '@mui/material';

function FinalizeSignIn() {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const finalizeSignIn = async () => {
      if (isSignInWithEmailLink(auth, window.location.href)) {
        let email = window.localStorage.getItem('emailForSignIn');
        if (!email) {
          email = window.prompt('Please provide your email for confirmation');
        }

        try {
          const result = await signInWithEmailLink(auth, email, window.location.href);
          dispatch(setUser(result.user));
          window.localStorage.removeItem('emailForSignIn');
          navigate('/home');
        } catch (error) {
          console.error('Error signing in with email link:', error);
          setError('Failed to sign in. Please try again.');
        } finally {
          setIsLoading(false);
        }
      } else {
        setError('Invalid sign-in link.');
        setIsLoading(false);
      }
    };

    finalizeSignIn();
  }, [dispatch, navigate]);

  if (isLoading) {
    return (
      <Container component="main" maxWidth="xs">
        <Box sx={{ mt: 8, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <CircularProgress />
          <Typography sx={{ mt: 2 }}>Finalizing sign-in...</Typography>
        </Box>
      </Container>
    );
  }

  if (error) {
    return (
      <Container component="main" maxWidth="xs">
        <Box sx={{ mt: 8, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Typography color="error">{error}</Typography>
        </Box>
      </Container>
    );
  }

  return null;
}

export default FinalizeSignIn;