import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const theme = createTheme({
  // Customize your theme here
});

function ThemedComponent({children}) {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
       {children}
    </ThemeProvider>
  );
}

export default ThemedComponent;
