import React from 'react';

import ReduxProvider from 'providers/ReduxProvider';
import ThemeProvider from 'providers/ThemeProvider';
import RoutesProvider from 'providers/RoutesProvider';
import AuthStateListener from 'providers/AuthStateListener';

function ThemedComponent() {
  return (
    <ReduxProvider>
      <ThemeProvider>
        <AuthStateListener>
          <RoutesProvider />
        </AuthStateListener>
      </ThemeProvider>
    </ReduxProvider>
  );
}

export default ThemedComponent;
