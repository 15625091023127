// src/components/SignIn.js
import { useState } from 'react';
// import { sendSignInLinkToEmail } from 'firebase/auth';
// import { httpsCallable } from "firebase/functions";

// import { auth, functions } from '../firebase/config';
import { 
  Button, 
  TextField, 
  Box, 
  Typography, 
  Container, 
  Paper, 
  Avatar,
  CircularProgress,
  Snackbar
} from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';

// const sendSignInLinkToEmail = httpsCallable(functions, 'sendSignInLinkToEmail');

const sendSignInLinkToEmail = async (email) => {
    try {
      const continueUrl = process.env.NODE_ENV === 'production'
      ? "https://portal.gulfpro.io/finalize-signin"
      : "https://b144b74f1cb6493c849ac850c98b9ce6.vfs.cloud9.us-east-2.amazonaws.com/finalize-signin"
      const response = await fetch(
        "https://us-central1-gps-portal-a13f8.cloudfunctions.net/authEmailLink",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email, continueUrl }),
          mode: 'no-cors',
        }
      );
      
      const data = await response.json();
      if (response.ok && data.success) {
        return data;
      } else {
        return console.error(data.error || "Failed to send sign-in link");
      }
    } catch (error) {
      console.error("Error in sendSignInLinkToEmail:", error);
      return console.error(
        "An unexpected error occurred while sending the sign-in link"
      );
    }
  }

function SignIn() {
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const handleSignIn = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    const actionCodeSettings = {
      url: `${window.location.origin}/finalize-signin`,
      handleCodeInApp: true
    };

    try {
      // await sendSignInLinkToEmail(auth, email, actionCodeSettings);
      await sendSignInLinkToEmail(email);
      window.localStorage.setItem('emailForSignIn', email);
      setSnackbarMessage('Sign-in link sent to your email!');
      setSnackbarOpen(true);
    } catch (error) {
      console.error('Error sending sign-in link:', error);
      setSnackbarMessage('Failed to send sign-in link. Please try again.');
      setSnackbarOpen(true);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <Paper elevation={3} sx={{
        marginTop: 8,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: 4,
      }}>
        <Avatar sx={{ m: 1, bgcolor: 'secondary.main', width: 56, height: 56 }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5" sx={{ mt: 2 }}>
          Gulf-Pro Services
        </Typography>
        <Typography component="h2" variant="h6" sx={{ mb: 3 }}>
          Customer Portal
        </Typography>
        <Typography component="h3" variant="subtitle1" sx={{ mb: 3 }}>
          Sign In with Email Link
        </Typography>
        <Box component="form" onSubmit={handleSignIn} sx={{ mt: 1, width: '100%' }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            disabled={isLoading}
          >
            {isLoading ? <CircularProgress size={24} /> : 'Send Sign-In Link'}
          </Button>
        </Box>
      </Paper>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
      />
    </Container>
  );
}

export default SignIn;